import React from "react"
import styled from "styled-components"

const MobileAboutInfo = ({ selected, helpArr, newSelect, obj }) => {
  return (
    <main className="mobile">
      <DeciderSection selected={selected}>
        {helpArr().map((el, i) => (
          <>
            <div key={i} onClick={() => newSelect(i + 1)}>
              <h4>{el}</h4>
              <section className="links">
                {" "}
                {selected === i + 1 ? "↑" : "↓"}
              </section>
            </div>
            {selected === i + 1 && (
              <ul className="open">
                {obj[i + 1].map(el => (
                  <li className="body" key={el}>
                    {el}
                  </li>
                ))}
              </ul>
            )}
          </>
        ))}
      </DeciderSection>
    </main>
  )
}

export default MobileAboutInfo

const DeciderSection = styled.div`
  div {
    padding-bottom: 1.6rem;
    border-bottom: 1px solid var(--beige);
    display: flex;
    align-content: center;
    cursor: pointer;
    color: var(--beige);
    justify-content: space-between;
  }
  ul {
    padding-bottom: 16px;
    list-style-position: inside;
  }
  div:nth-of-type(${props => props.selected}) {
    color: var(--grey);
    border: none;
  }
  div + div {
    padding-top: 1.6rem;
  }
  .open {
    //animation
    border: none;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    /* Firefox < 16 */
    @-moz-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`
