import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { chooseText } from "../utils/chooseText"
import { useLanguage } from "../context/LanguageContext"
import { AboutHeader, TeamArea, AboutUsInfo } from "../components/AboutUsPage"
import { useSpecialPage } from "../hooks/useSpecialPage.hook"
import Layout from "../components/layout"

export const query = graphql`
  query {
    team: allSanityTeamMember(sort: { order: ASC, fields: order }) {
      edges {
        node {
          title
          position
          order
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    allSanityHomepage {
      edges {
        node {
          servicesList_de
          servicesList_en
        }
      }
    }

    allSanityAboutpage {
      edges {
        node {
          imageLeft {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          imageCenter {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          imageRight {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          sectionTitle_en
          sectionTitle_de
          headline_de
          headline_en
          titleConsult_de
          titleConsult_en
          titleHelp_de
          titleHelp_en
          titleProtect_de
          titleProtect_en
          secondHeadline_en
          secondHeadline_de
          buttonAction_en
          buttonAction_de
          emailOpen_en
          emailOpen_de
          descriptionConsult_de
          descriptionConsult_en
          descriptionHelp_de
          descriptionHelp_en
          descriptionProtect_de
          descriptionProtect_en
        }
      }
    }
  }
`

const About = ({ data }) => {
  const team = data.team.edges.map(({ node }) => node)
  const { language } = useLanguage()
  useSpecialPage(false)
  const [main] = data.allSanityAboutpage.edges.map(({ node }) => node)
  const [servicesList] = data.allSanityHomepage.edges.map(({ node }) => node)

  const images = [main.imageLeft, main.imageCenter, main.imageRight]

  return (
    <>
      <SEO title={chooseText("sectionTitle", main, language)} />
      <Layout>
        <AboutHeader images={images} main={main} servicesList={servicesList} />
        <AboutUsInfo main={main} />
        <TeamArea main={main} team={team} />
      </Layout>
    </>
  )
}

export default About
