import React from "react"
import styled from "styled-components"

const DesktopInfo = ({ selected, helpArr, newSelect, obj }) => {
  return (
    <main
      data-sal="fade"
      data-sal-duration="300"
      data-sal-delay="300"
      data-sal-easing="ease"
      className="desktop"
    >
      <DeciderSection selected={selected}>
        {helpArr().map((el, i) => (
          <div key={i} onClick={() => newSelect(i + 1)}>
            <h4>{el}</h4>
            <section className="links">⟶</section>
          </div>
        ))}
      </DeciderSection>
      {selected && (
        <ul>
          {obj[selected].map(el => (
            <li className=" open body" key={el}>
              {el}
            </li>
          ))}
        </ul>
      )}
    </main>
  )
}

export default DesktopInfo

const DeciderSection = styled.div`
  div {
    padding-bottom: 1.6rem;
    border-bottom: 1px solid var(--beige);
    display: flex;
    align-content: center;
    cursor: pointer;
    color: var(--beige);
    justify-content: space-between;
  }
  div:nth-of-type(${props => props.selected}) {
    color: var(--grey);
  }
  div + div {
    padding-top: 1.6rem;
  }
`
