import React from "react"
import styled from "styled-components"
import { ContentWrapper } from "../../styles/Wrapper"
import { chooseText } from "../../utils/chooseText"
import { useLanguage } from "../../context/LanguageContext"

import { useNav } from "../../context"
import Observer from "@researchgate/react-intersection-observer"
import { Landing } from "../../styles/Landing"
import Img from "gatsby-image/withIEPolyfill"

export const AboutHeader = ({ images, main }) => {
  const { language } = useLanguage()
  const { checkIntersection } = useNav()
  return (
    <Landing style={{ paddingTop: "var(--navHeight)" }}>
      <ContentWrapper style={{ width: "100%" }}>
        <div
          className="content "
          data-sal="fade"
          data-sal-duration="600"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <Observer rootMargin="-75px 0% -75% 0%" onChange={checkIntersection}>
            <h6 className="sections">
              {chooseText("sectionTitle", main, language)}
            </h6>
          </Observer>
          <h2>{chooseText("headline", main, language)}</h2>
        </div>
        <AboutPageImages>
          {images?.map((el, i) => (
            <div>
              <Img
                objectFit="cover"
                objectPosition="center"
                fluid={el?.asset?.fluid}
                key={i}
                alt=""
              />
            </div>
          ))}
        </AboutPageImages>
      </ContentWrapper>
    </Landing>
  )
}

const AboutPageImages = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: scroll;
  position: absolute;
  width: 100%;
  bottom: -${350 / 2}px;
  height: 35rem;
  .gatsby-image-wrapper {
    width: 390px;
    height: 390px;
  }
  img {
    object-fit: center;
    width: 390px;
    height: 390px;
  }
  @media (max-width: 1024px) {
    height: 20.3rem;
    bottom: -${173 / 2}px;
    display: flex;
    align-items: center;
  }

  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 146, 154, 0.4); */
    background-color: white;
    margin: 100px 0;
    border-radius: 6px;
  }

  ::-webkit-scrollbar {
    border-radius: 6px;
    border: solid 3px transparent;
    margin: 100px 0;
    background-color: rgba(0, 146, 154, 0.4);
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border: solid 3px transparent;
    background-color: var(--blue);
  }
`
