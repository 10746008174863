import React from "react"
import styled from "styled-components"
import { ContentWrapper } from "../../styles/Wrapper"
import { chooseText } from "../../utils/chooseText"
import { useLanguage } from "../../context/LanguageContext"
import { Button } from "../../styles/Buttons"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"
export const TeamArea = ({ main, team }) => {

  const { language } = useLanguage()
  // const teamMembers = new Array(10).fill()
  return (
    <TeamSection id="team">
      <ContentWrapper>
        <main>
          <h3>{chooseText("secondHeadline", main, language)}</h3>
          <div className="grid">
            {team.map((el, i) => (
              <>
                <TeamMember
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="500"
                  data-sal-easing="ease"
                  key={i}
                >
                  <div className="pic">
                    {el?.image ? (
                      <Img fluid={el?.image?.asset?.fluid} />
                    ) : (
                      <div className="pic"></div>
                    )}
                  </div>
                  <h5>{el.title}</h5>
                  <div className="body">{el.position}</div>
                </TeamMember>
              </>
            ))}
          </div>
          <div
            data-sal="fade"
            data-sal-duration="600"
            data-sal-delay="500"
            data-sal-easing="ease"
            className="contactUs"
          >
            <h5 style={{ textAlign: "center" }}>
              {chooseText("buttonAction", main, language)}
            </h5>
            <AniLink to="/contact" cover bg="var(--green)" direction="left">
              <Button>{chooseText("emailOpen", main, language)}</Button>
            </AniLink>
          </div>
        </main>
      </ContentWrapper>
    </TeamSection>
  )
}

const TeamSection = styled.section`
  padding: 8rem 0;
  /* background: var(--backgrounds); */
  background: white;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      margin-bottom: 4rem;
    }
  }

  .contactUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8rem 0 11.2rem;
    h5 {
      margin-bottom: 1.6rem;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    grid-column-gap: 2.4rem;
    grid-row-gap: 8rem;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 600px) {
    article {
      height: 200px;
    }
    .grid {
      grid-template-columns: auto;
    }
  }
`

const TeamMember = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .pic {
    height: 100% !important;
      width: 100% !important;
    .gatsby-image-wrapper {
      height: 100% !important;
      width: 100% !important;
    }
    /* padding-bottom: 100%; */
    background: var(--beige);
    width: 100%;
    height: 100%;
  }
  h5 {
    color: var(--green);
    margin-top: 1.6rem;
  }
`
