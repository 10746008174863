import React, { useState, useCallback } from "react"
import { ContentWrapper } from "../../styles/Wrapper"
import { chooseText } from "../../utils/chooseText"
import { useLanguage } from "../../context/LanguageContext"
import styled from "styled-components"
import DesktopInfo from "./DesktopInfo"
import MobileAboutInfo from "./MobileAboutInfo"

export const AboutUsInfo = ({ main }) => {

  const { language } = useLanguage()
  const [selected, setSelected] = useState(1)

  const helpArr = useCallback(() => {
    return [
      chooseText("titleConsult", main, language),
      chooseText("titleHelp", main, language),
      chooseText("titleProtect", main, language)
    ]
  }, [main, language])

  const obj = {
    1: chooseText("descriptionConsult", main, language),
    2: chooseText("descriptionHelp", main, language),
    3: chooseText("descriptionProtect", main, language)
  }
  

  function newSelect(newNum) {
    setSelected(newNum)
  }
  return (
    <AboutUsInfoSection id="detail">
      <ContentWrapper>
        <DesktopInfo
          selected={selected}
          helpArr={helpArr}
          newSelect={newSelect}
          obj={obj}
        />
        <MobileAboutInfo
          selected={selected}
          helpArr={helpArr}
          newSelect={newSelect}
          obj={obj}
        />
      </ContentWrapper>
    </AboutUsInfoSection>
  )
}

const AboutUsInfoSection = styled.section`
  padding: ${350 / 2 / 10 + 8}rem 0 8rem 0;
  background: white;
  color: var(--grey);
  @media (max-width: 1024px) {
    padding: ${173 / 2 / 10 + 5.6}rem 0 5.6rem 0;
  }
  @media (max-width: 600px) {
    padding: ${296 / 2 / 10 + 5.6}rem 0 5.6rem 0;
  }
  .desktop {
    padding: 0 8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10.4rem;
    @media (max-width: 1440px) {
      grid-template-columns: 1fr 1.75fr;
    }
  }
  ul {
    list-style-type: disc;
  }
  @media (max-width: 1440px) {
    .desktop {
      padding: 0;
    }
  }
  @media (max-width: 1024px) {
    .desktop {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    .mobile {
      display: none;
    }
  }
  .open {
    //animation
    border: none;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    /* Firefox < 16 */
    @-moz-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`
